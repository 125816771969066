<template>
	<div>
        <ValidationObserver ref="schForm">
        <b-row>
            <div class="col-12 col-md-4 col-lg-4 col-xl-4">
                <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('scholarship_type')">
                        <parameter-selectbox
                            code="graduate_application_scholarship_types"
                            v-model="type"
                            :validate-error="errors[0]"
                        ></parameter-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-12 col-md-5 col-lg-5 col-xl-5" v-if="['coop_scholarship','public_scholarship'].includes(type)">
                <ValidationProvider name="schType" :rules="['coop_scholarship','public_scholarship'].includes(type) ? 'required' : ''" v-slot="{valid, errors}">
                    <b-form-group :label="type == 'coop_scholarship' ? $t('scholarship_coop') : $t('scholarship_public')">
                        <graduate-application-scholarships-selectbox
                            :type="type"
                            v-model="schType"
                            :validate-error="errors[0]"
                        ></graduate-application-scholarships-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-12 col-md-3 col-lg-3 col-xl-3" v-if="['coop_scholarship','public_scholarship'].includes(type)">
                <ValidationProvider name="rate" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('scholarship_rate')">
                        <b-form-input
                            :disabled="true"
                            v-model="rate"
                            :state="errors[0] ? false : null"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6" v-if="!['coop_scholarship','public_scholarship'].includes(type)">
                <ValidationProvider name="rate" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('scholarship_rate')">
                        <b-form-input
                            type="number"
                            min="0"
                            max="100"
                            step="5"
                            v-check-min-max-value
                            v-model="rate"/>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                <b-button @click="saveScholarship" variant="primary">{{$t('add')}}</b-button>
            </div>
        </b-row>
            <b-row>
                <div class="col-12">
                    <hr>
                </div>
                <div class="col-12">
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        hover
                        striped
                        responsive
                        :items="scholarships"
                        :fields="scholarshipsFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                        <template #cell(buttons)="row">
                            <div>
                                <b-button @click="deleteScholarship(row)" variant="danger"><i class="ri-delete-bin-line"></i></b-button>
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
	// Components
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox"
    import GraduateApplicationScholarshipsSelectbox from "@/components/interactive-fields/GraduateApplicationScholarshipsSelectbox";
	// Services
    import GraduateApplicationService from "@/services/GraduateApplicationService";

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from "qs";

	export default {
		components: {
            ParameterSelectbox,
            ScholarshipRateSelectbox,
            GraduateApplicationScholarshipsSelectbox,

			ValidationProvider,
			ValidationObserver,
		},
		props: {
            row: {
                type: Object
            },
            graduateApplicationId: {
                type: Number
            },
            nationalId: {
                type: String
            },
            pin: {
                type: String
            }
		},
		data() {
			return {
                type: null,
                schType: null,
                rate: null,

                scholarships: [],
                scholarshipsFields: [
                    {
                        key: 'name',
                        label: this.$t('scholarship_type').toUpper(),
                    },
                    {
                        key: 'rate',
                        label: this.$t('scholarship_rate'),
                    },
                    {
                        key: 'buttons',
                        label: '',
                    },
                ],
			}
		},
		watch: {
            row: function(val){
                this.getScholarships()
            },

            schType: function (val){
                this.getScholarshipRate(val)
            },
            type: function (val) {
                this.rate = null
            }
		},
		created() {

		},
		methods: {
            getScholarships(){
                this.scholarships = []
                let config = {
                    national_id: this.nationalId,
                    pin: this.pin
                }
                GraduateApplicationService.show(config).then(response => {
                    let data = response.data.data
                    data.preferences.forEach(itm => {
                        if(itm.id == this.row.perferenceId) {
                            if(itm.scholarships) {
                                Object.keys(itm.scholarships).forEach((item) => {
                                    this.scholarships.push({
                                        name: itm.scholarships[item].detail == null ? itm.scholarships[item].name : itm.scholarships[item].name+' - '+itm.scholarships[item].detail,
                                        rate: '%'+itm.scholarships[item].rate,
                                        code: item
                                    })
                                })
                            }
                        }
                    })
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.'+e.data.message));
                        }
                    }
                })
            },

            async saveScholarship(){
                const isValid = await this.$refs.schForm.validate();
                if (isValid) {

                    let formData = {
                        graduate_application_id: this.graduateApplicationId,
                        preference_id: this.row.perferenceId,
                        scholarship_type: this.type,
                        scholarship_rate: ['coop_scholarship','public_scholarship'].includes(this.type) ? parseInt(this.rate.replace('%','')) : this.rate
                    }
                        if(['coop_scholarship','public_scholarship'].includes(this.type)){
                            formData.scholarship_type_id = this.schType
                        }

                    GraduateApplicationService.scholarshipSave(formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message));
                        this.type = null
                        this.rate = null
                        this.description = null
                        this.$refs.schForm.reset()
                        this.getScholarships()
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.'+e.data.message));
                            }
                        } else {
                            if (e.data.errors.scholarship_type) {
                                this.$refs.schForm.errors.type.push(e.data.errors.scholarship_type[0]);
                            }
                            if (e.data.errors.scholarship_rate) {
                                this.$refs.schForm.errors.rate.push(e.data.errors.scholarship_rate[0]);
                            }
                            if (e.data.errors.description) {
                                this.$refs.schForm.errors.description.push(e.data.errors.description[0]);
                            }
                        }
                    })
                }
            },

            deleteScholarship(row){
                let formData = {
                    params: {
                        graduate_application_id: this.graduateApplicationId,
                        preference_id: this.row.perferenceId
                    },

                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                GraduateApplicationService.deleteScholarship(row.item.code,formData).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message));
                    this.getScholarships()
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.'+e.data.message));
                        }
                    }
                })
            },

            getScholarshipRate(id){
                const config = {
                    params: {
                        filter: {
                            id: id
                        }
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                GraduateApplicationService.scholarships(config).then(response => {
                    this.rate = '%'+response.data.data[0].rank
                })
            }
        }
	}
</script>
